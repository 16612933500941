import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Query } from "@sinequa/core/app-utils";

import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AdvancedService } from "@sinequa/components/advanced";
import { FirstPageService, SearchService } from "@sinequa/components/search";
import { SearchFormComponent } from "@sinequa/components/search-form/search-form.component";
import { UserPreferences } from "@sinequa/components/user-settings";
import { AppService } from "@sinequa/core/app-utils";
import { LoginService } from "@sinequa/core/login";
import { NotificationsService } from "@sinequa/core/notification";
import { Subscription } from "rxjs";
import { FEATURES } from "../../config";
import { SharedCommonService } from "../services/shared-common.service";

@Component({
  selector: "app-search-form",
  templateUrl: "./search-form.component.html",
  styleUrls: ["./search-form.component.scss"],
})
export class SdeSearchComponent implements OnInit, OnDestroy, OnChanges {
  searchControl: FormControl;
  form: FormGroup;
  autofocus = 0;
  autoSuggestData: any;

  /** Expression from the query selects, if any ("simple"/"selects" field search mode) */
  fieldSearchExpression?: string;

  /** Result of query parsing ("advanced" field search mode) */
  parseResult?: any;

  /** A reference to the AutocompleteExtended directive, needed to get the field search selections, if any */

  @ViewChild("searchInput") searchInput: ElementRef;
  @Input() searchRoute = "search";

  // Advanced search flags
  showAdvancedSearch: boolean;
  initAdvanced: boolean;
  enableAdvancedForm = false; // Show the advanced form button or not

  /** Define if a filter, NOT belonging to fielded & advanced search, is currently applied to the searchService.query */
  isFiltering = false;

  keepFiltersTitle = "msg#searchForm.notKeepFilters";
  enableKeepFilters = true; // Show the "keep filters" button or not

  /** USED ALONG WITH keepFilters context, to optionally reset the advanced-search or not */
  keepAdvancedSearchFilters = false;

  /** Define if should stay on the same tab even after a new search */
  keepTab = false;
  isDropdownOpen: boolean;

  /** Voice recognition */
  voiceRecognitionState = false;
  enableVoiceRecognition = true; // Show the voice recognition button or not

  hasScroll = false;

  private subscriptions: Subscription[] = [];

  @ViewChild("searchForm") searchForm: SearchFormComponent;

  constructor(
    public searchService: SearchService,
    public loginService: LoginService,
    private formBuilder: FormBuilder,
    public notificationsService: NotificationsService,
    public appService: AppService,
    public prefs: UserPreferences,
    public firstPageService: FirstPageService,
    public router: Router,
    public advancedService: AdvancedService,
    public route: ActivatedRoute,
    public sharedCommonService: SharedCommonService
  ) {}

  ngOnChanges(changes: SimpleChanges) {}

  /**
   * Initialization of the form
   */
  ngOnInit() {
    this.searchControl = new FormControl("");
    // this.autoSuggest();
    this.form = this.formBuilder.group({
      search: this.searchControl,
    });

    this.sharedCommonService.isDropdownOpen.subscribe((data) => {
      this.isDropdownOpen = data;
    });

    // Every time the query changes, we want to update the search form
    this.subscriptions.push(
      this.searchService.queryStream.subscribe((query) => {
        // Update main search bar
        this.searchControl.setValue(this.searchService.query?.text || "");
        this.autofocus++;
        // Update advanced form
        this.form
          .get("treepath")
          ?.setValue(this.advancedService.getValue("treepath"));
        this.form
          .get("authors")
          ?.setValue(this.advancedService.getValue("authors"));
        this.form
          .get("size")
          ?.setValue(this.advancedService.getRangeValue("size"));
        this.form
          .get("modified")
          ?.setValue(this.advancedService.getRangeValue("modified"));
        this.form
          .get("docformat")
          ?.setValue(this.advancedService.getValue("docformat"));
      })
    );

    // Initialize the search form options (either now, or when login is complete)
    if (this.appService.app) {
      this.setOptions();
    } else {
      this.subscriptions.push(
        this.loginService.events.subscribe((event) => {
          if (this.appService.app) {
            this.setOptions();
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.map((item) => item.unsubscribe());
  }

  setOptions() {
    const features =
      (this.appService.app?.data?.features as string[]) || FEATURES;
    features.forEach((feature) => {
      switch (feature) {
        case "advanced-form":
          this.enableAdvancedForm = true;
          break;
        case "keep-advanced-form-filters":
          this.keepAdvancedSearchFilters = true;
          break;
        case "keep-tab":
          this.keepTab = true;
          break;
        case "toggle-keep-filters":
          this.enableKeepFilters = true;
          break;
        case "voice-recognition":
          this.enableVoiceRecognition = true;
          break;
      }
    });
  }

  onAutocompleteSearch(text: string, query: Query) {
    query.text = text;
    this.searchForm.applyFilters(); // Apply the autocomplete query and close the form
  }

  onAutocompleteSelect(text: string, query: Query) {
    query.text = text;
  }

  /**
   * Retrieve autocomplete sources, which include the standard
   */
  get autocompleteSources(): string[] {
    return (this.appService.app?.data?.features as string[]) || FEATURES;
  }
}
