import { Location } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from "@angular/router";
import { Action } from "@sinequa/components/action";
import {
  PreviewHighlightColors,
  PreviewService,
} from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { UserPreferences } from "@sinequa/components/user-settings";
import { UIService } from "@sinequa/components/utils";
import { AppService, Query } from "@sinequa/core/app-utils";
import { IntlService } from "@sinequa/core/intl";
import { LoginService } from "@sinequa/core/login";
import { MODAL_MODEL } from "@sinequa/core/modal";
import {
  NotificationsService,
  NotificationType,
} from "@sinequa/core/notification";
import {
  AuditEventType,
  Record,
  Results,
  Tab,
} from "@sinequa/core/web-services";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { METADATA, PREVIEW_HIGHLIGHTS } from "../../config";
import googleAnalyticsConstants from "../analytics/google-analytics.constant";
import { SharedCommonService } from "../services/shared-common.service";
import { SdePreviewComponent } from "./../../inherited/sde-preview/sde-preview.component";
import { SdePreviewData, SdeRecord } from "./../../model/extended.interface";

export interface PreviewConfig {
  initialCollapsedPanel?: boolean;
  homeRoute?: string;
  resultsRoute?: string;
  showBackButton?: boolean;
  subpanels?: string[];
  defaultSubpanel?: string;
  previewSearchable?: boolean;
}

export interface PreviewInput {
  config?: PreviewConfig;
  id: string;
  query: Query;
}

export interface EntitiesState {
  count: number;
  sortFreq: boolean;
  hidden: Map<string, boolean>;
  nav: Map<string, number>;
  category: string;
}

export const PREVIEW_CONFIG = new InjectionToken<PreviewConfig>(
  "PREVIEW_CONFIG"
);

@Component({
  selector: "sde-common-full-preview",
  templateUrl: "./sde-common-full-preview.component.html",
  styleUrls: ["./sde-common-full-preview.component.scss"],
})
export class FullPagePreviewComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  // Inputs can be passed via binding or the URL + deps injection (defaults are initialized below)
  @Input() id?: string;
  @Input() query?: Query;
  @Input() previewConfig?: PreviewConfig;
  @ViewChild(SdePreviewComponent) preview: SdePreviewComponent;

  // Set when the preview service responds
  previewData?: SdePreviewData;
  downloadUrl?: string;
  currentUrl?: string;
  sandbox?: string | null;
  loading = false;

  // State of the preview
  collapsedPanel = false;
  homeRoute = "/home";
  resultsRoute = "/search";
  showBackButton = true;
  subpanels: string[];
  subpanel = "";

  previewSearchable = true;
  showPreview: boolean = false;
  minimapType = "extractslocations";
  allRecord: any;

  // Page management for splitted documents
  pagesResults: Results;

  // Subscriptions
  private loginSubscription: Subscription;
  private routerSubscription: Subscription;

  // Preview Tooltip
  tooltipEntityActions: Action[] = [];
  tooltipTextActions: Action[] = [];

  private readonly scaleFactorThreshold = 0.05;
  scaleFactor = 1.0;
  tabs: any[];
  extractsType: string;

  constructor(
    @Optional() @Inject(PREVIEW_CONFIG) previewConfig: PreviewConfig,
    @Optional() @Inject(MODAL_MODEL) previewInput: PreviewInput,
    protected router: Router,
    protected route: ActivatedRoute,
    protected titleService: Title,
    protected _location: Location,
    public loginService: LoginService,
    protected intlService: IntlService,
    protected previewService: PreviewService,
    protected searchService: SearchService,
    private appService: AppService,
    public prefs: UserPreferences,
    public ui: UIService,
    protected activatedRoute: ActivatedRoute,
    public sharedCommonService: SharedCommonService,
    private notificationsService: NotificationsService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private $gaService: GoogleAnalyticsService
  ) {
    this.loading = true;
    // If the page is refreshed login needs to happen again, then we can get the preview data
    this.loginSubscription = this.loginService.events.subscribe({
      next: (event) => {
        if (event.type === "session-changed") {
          this.getPreviewData();
        }
      },
    });

    // The URL can be changed when searching within the page
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof RouterEvent && event.url !== this.homeRoute
        )
      )
      .subscribe({
        next: (event) => {
          if (event instanceof NavigationEnd) {
            this.getPreviewDataFromUrl();
          }
        },
      });

    // In case the component is loaded in a modal
    if (previewInput) {
      if (previewInput.config) {
        previewConfig = previewInput.config;
      }
      this.id = previewInput.id;
      this.query = previewInput.query;
    }

    // Configuration may be injected by the root app (or as above by the modal)
    if (previewConfig) {
      this.previewConfig = previewConfig;
    }

    this.tooltipTextActions.push(
      new Action({
        text: "msg#searchForm.search",
        title: "msg#preview.searchText",
        icon: "sq-preview-search-icon",
        action: (action, event) => {
          if (this.query) {
            this.query.text = event["text"].slice(0, 50);
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { query: this.query.toJsonForQueryString() },
              queryParamsHandling: "merge",
              state: {},
            });
          }
        },
      })
    );
  }

  /**
   * Loads the preview data in the case where id and query are provided as inputs
   * (eg. the component is inserted in a parent rather than as a route)
   */
  ngOnChanges() {
    this.getPreviewData();
  }

  /**
   * Initializes the configuration and potentially loads the preview data from the URL
   * (in the case where the id and query are not provided via the Input bindings)
   */
  ngOnInit() {
    if (!this.id || !this.query) {
      // do nothing if the parameters are already here
      this.getPreviewDataFromUrl();
    }

    if (this.previewConfig) {
      if (this.previewConfig.initialCollapsedPanel !== undefined) {
        this.collapsedPanel = this.previewConfig.initialCollapsedPanel;
      }
      if (this.previewConfig.homeRoute !== undefined) {
        this.homeRoute = this.previewConfig.homeRoute;
      }
      if (this.previewConfig.showBackButton !== undefined) {
        this.showBackButton = this.previewConfig.showBackButton;
      }

      if (this.previewConfig.subpanels !== undefined) {
        this.subpanels = this.previewConfig.subpanels;
      }
      if (this.previewConfig.defaultSubpanel !== undefined) {
        this.subpanel = this.previewConfig.defaultSubpanel;
      }

      if (this.previewConfig.previewSearchable !== undefined) {
        this.previewSearchable = this.previewConfig.previewSearchable;
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showPreview = true;
    }, 1000);
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }

  /**
   * Extracts id and query from the URL and request the preview data from the preview service
   */
  private getPreviewDataFromUrl() {
    const map = this.route.snapshot.queryParamMap;
    this.id = map.get("id") || undefined;
    this.query = this.searchService.query.copy();
    this.query.fromJson(map.get("query") || "{}");
    this.getPreviewData();
  }

  public get previewHighlights(): PreviewHighlightColors[] {
    return (
      (this.appService.app?.data?.previewHighlights as any) ||
      PREVIEW_HIGHLIGHTS
    );
  }

  /**
   * Performs a call to the preview service. Update the search form with the searched query text (page refresh or navigation)
   */
  private getPreviewData() {
    if (!!this.id && !!this.query && this.loginService.complete) {
      this.previewService
        .getPreviewData(this.id, this.query)
        .subscribe((previewData) => {
          this.previewData = previewData as SdePreviewData;
          // NB: Metadata viewer does not have entities and extracts so only related docs tab is relevant for it
          if (this.previewData?.record.hasOwnProperty("isMetadataViewer")) {
            this.subpanels = ["relateddocumentsmetadata"];
            this.subpanel = "relateddocumentsmetadata";
          } else {
            this.subpanels = ["extracts", "entities", "relateddocuments"];
            this.subpanel = "extracts";
          }
          const url = previewData?.documentCachedContentUrl;
          // Manage splitted documents
          const pageNumber = this.previewService.getPageNumber(
            previewData.record
          );
          if (pageNumber) {
            this.previewService
              .fetchPages(previewData.record.containerid!, this.query!)
              .subscribe((results) => (this.pagesResults = results));
          }
          this.currentUrl = url;

          this.sandbox = ["xlsx", "xls"].includes(previewData.record.docformat)
            ? null
            : undefined;
          previewData?.record?.title
            ? this.titleService.setTitle(
                this.intlService.formatMessage(
                  googleAnalyticsConstants.currentPage.previewPage,
                  {
                    title: previewData?.record?.title,
                  }
                )
              )
            : this.titleService.setTitle(
                googleAnalyticsConstants.currentPage.previewPage
              );

          this.loading = true;
        });
    }
  }

  /**
   * Called when the HTML of the preview finishes loading in the iframe
   * @param previewDocument
   */
  onPreviewReady() {
    if (this.id && this.previewData) {
      this.preview.selectMostRelevant();
      this.tabs = [this.getTab("extracts"), this.getTab("entities")];
      this.extractsType = this.previewData.highlightsPerCategory[
        "matchingpassages"
      ]?.values.length
        ? "matchingpassages"
        : "extractslocations";
      this.titleService.setTitle(
        this.intlService.formatMessage("msg#preview.pageTitle", {
          title: this.previewData.record?.title || "",
        })
      );
      this.cdRef.detectChanges();
      this.loading = false;
    }
  }

  private getTab(panel: string): Tab {
    return {
      name: panel,
      display: `msg#preview.${panel}`,
      value: panel,
      count: 1,
    };
  }

  /**
   * Back button (navigating back to search)
   **/

  back() {
    // const history = this.previewService.history.filter((h) => !!h);
    if (history.length < 2) {
      this.backToSearchPage();
    } else {
      // this.previewService.back();
    }
  }

  /**
   * Navigate back to search page uses a copy of the original search query
   **/
  backToSearchPage() {
    this._location.back();
  }

  /**
   * Opens source document in full page preview
   */
  goToSourceDoc() {
    window.open(this.previewData?.record.download_url, "_blank");
    if (this.sharedCommonService.isOptionalGA4EventActive) {
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.openOrigDocumentFromSource,
        0,
        true,
        {
          app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
          page: this.sharedCommonService.getAppDetailsForGA().previewPage,
          debug: "true",
          url: this.router.url,
          ...this.sharedCommonService.createDocumentEventDetailsObject(
            this.sharedCommonService.getAppDetailsForGA().app_name,
            this.previewData?.record.title,
            this.previewData?.record.collection,
            this.previewData?.record.id,
            this.searchService.query.text,
            this.searchService.query.scope,
            this.sharedCommonService.getAppliedFilterValues()
          ),
        }
      );
    }
  }

  navigateToSdeResults() {
    this.searchService.query.text = "";
    this.searchService.searchText("search");
  }

  /**
   * Search for new text within the same document
   * @param text
   */
  searchText(text: string) {
    if (this.query && this.query.text !== text) {
      this.query.text = text;
      this.query.scope = this.searchService.query.scope;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { query: this.query.toJsonForQueryString() },
        queryParamsHandling: "merge",
        state: {},
      });
    }
  }

  /*
   * Stop loader when data loaded successfully in Metadata Viewer
   */
  stopLoader(event: any) {
    this.loading = false;
  }

  // User preferences

  /**
   * Entity facets state
   */
  get entitiesStartUnchecked(): { [entity: string]: boolean } {
    return this.prefs.get("preview-entities-checked") || {};
  }

  entitiesChecked(event: { entity: string; checked: boolean }) {
    const startUnchecked = this.entitiesStartUnchecked;
    startUnchecked[event.entity] = !event.checked;
    this.prefs.set("preview-entities-checked", startUnchecked);
  }

  increaseScaleFactor() {
    this.scaleFactor = this.scaleFactor + this.scaleFactorThreshold;
    return false;
  }

  decreaseScaleFactor() {
    this.scaleFactor =
      Math.round(
        Math.max(0.1, this.scaleFactor - this.scaleFactorThreshold) * 100
      ) / 100;
    return false;
  }

  shouldDisableMinimize() {
    return this.scaleFactor <= 0.1;
  }

  leftPanelTooltipPlacement() {
    return this.collapsedPanel ? "right" : "bottom";
  }

  public documentIconClass(document: any): string {
    const documentFormat = document.fileext;
    if (!documentFormat) {
      return "far fa-file sq-icon-file";
    }

    return "far fa-file sq-icon-file-" + document.fileext;
  }

  /**
   * Returns the configuration of the metadata displayed in the facet-preview component.
   * The configuration from the config.ts file can be overriden by configuration from
   * the app configuration on the server
   */
  public get metadata(): string[] {
    if (
      this.appService.app &&
      this.appService.app.data &&
      this.appService.app.data.metadata
    ) {
      return <string[]>(<any>this.appService.app.data.metadata);
    }
    return METADATA;
  }

  /**
   * @returns URL of the original document, if any
   */
  getOriginalDocUrl(): string | undefined {
    return (
      this.previewData?.record.url1 || this.previewData?.record.originalUrl
    );
  }

  /**
   * Notification for the audit service
   */
  notifyOriginalDoc() {
    if (this.previewData) {
      const type = this.previewData?.record.url1
        ? AuditEventType.Doc_Url1
        : AuditEventType.Doc_CacheOriginal;
      this.searchService.notifyOpenOriginalDocument(
        this.previewData.record,
        undefined,
        type
      );
    }
  }

  notifyPdf() {
    if (this.previewData) {
      this.searchService.notifyOpenOriginalDocument(
        this.previewData.record,
        undefined,
        AuditEventType.Doc_CachePdf
      );
    }
  }
  // Copy preview URL
  copyURL() {
    if (this.previewData?.record) {
      this.sharedCommonService.copyURL(
        this.previewData.record as Record,
        this.searchService.query
      );
      this.notificationsService.notify(
        NotificationType.Success,
        "msg#actionMenu.urlCopiedToClipboard",
        {},
        undefined,
        true
      );

      if (this.sharedCommonService.isOptionalGA4EventActive) {
        this.$gaService.event(
          googleAnalyticsConstants.action.click,
          googleAnalyticsConstants.category.button,
          googleAnalyticsConstants.label.copyDocumentLink,
          0,
          true,
          {
            app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
            page: this.sharedCommonService.getAppDetailsForGA().previewPage,
            debug: "true",
            url: this.router.url,
            ...this.sharedCommonService.createDocumentEventDetailsObject(
              this.sharedCommonService.getAppDetailsForGA().app_name,
              this.previewData?.record.title,
              this.previewData?.record.collection,
              this.previewData?.record.id,
              this.searchService.query.text,
              this.searchService.query.scope,
              this.sharedCommonService.getAppliedFilterValues()
            ),
          }
        );
      }
    }
  }

  // Download document with docformat = pdf
  downloadDocument(record: SdeRecord) {
    this.sharedCommonService.downloadDocument(record);
    if (this.sharedCommonService.isOptionalGA4EventActive) {
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.downloadDocument,
        0,
        true,
        {
          app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
          page: this.sharedCommonService.getAppDetailsForGA().previewPage,
          debug: "true",
          url: this.router.url,
          ...this.sharedCommonService.createDocumentEventDetailsObject(
            this.sharedCommonService.getAppDetailsForGA().app_name,
            record.title,
            record.collection,
            record.id,
            this.searchService.query.text,
            this.searchService.query.scope,
            this.sharedCommonService.getAppliedFilterValues()
          ),
        }
      );
    }
  }

  // Method to check whether the record if from HEK/GCN circular.
  isEventBasedMetadata(): boolean {
    const treepath = this.previewData?.record.treepath;
    return !!(
      treepath &&
      (treepath.includes("/Heliophysics/Heliophysics Events Knowledgebase/") ||
        treepath.includes("/Astrophysics/GCN Circulars/"))
    );
  }
}
