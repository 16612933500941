import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PreviewHighlightColors } from "@sinequa/components/preview";
import { AppService } from "@sinequa/core/app-utils";
import { PREVIEW_HIGHLIGHTS } from "../../config";
import { SdeRecord } from "../../model/extended.interface";
import { SharedCommonService } from "../services/shared-common.service";

@Component({
  selector: "app-sde-common-short-preview",
  templateUrl: "./sde-common-short-preview.component.html",
  styleUrls: ["./sde-common-short-preview.component.scss"],
})
export class SdeCommonShortPreviewComponent implements OnInit {
  constructor(
    private appService: AppService,
    private sharedCommonService: SharedCommonService
  ) {}

  entityHighlightFlag: boolean = true;
  extractsHighlightFlag: boolean = true;
  previewStyle: { height: string };

  @Input() openedDoc: SdeRecord;
  @Output() closeDocumentEvent: EventEmitter<any> = new EventEmitter();
  @Output() expandDocumentEvent: EventEmitter<any> = new EventEmitter();
  @Output() copyUrlEvent: EventEmitter<any> = new EventEmitter();
  @Output() downloadDocEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.sharedCommonService.closeMetadataViewer.subscribe((_data) => {
      this.closeDocument();
    });
  }

  getHeight(): string {
    return window?.innerHeight + "px";
  }

  toggleEntityHighlighting(flag: boolean) {
    this.entityHighlightFlag = flag;
  }

  toggleExtractsHighlighting(flag: boolean) {
    this.extractsHighlightFlag = flag;
  }

  closeDocument() {
    this.closeDocumentEvent.emit();
  }

  expandDocument(doc: SdeRecord) {
    this.expandDocumentEvent.emit({ document: doc });
  }

  copyUrl(doc: SdeRecord, fullPage: boolean) {
    this.copyUrlEvent.next({ document: doc });
  }

  downloadDocument(doc: SdeRecord) {
    this.downloadDocEvent.next({ document: doc });
  }

  public get previewHighlights(): PreviewHighlightColors[] {
    return (
      (this.appService.app?.data?.previewHighlights as any) ||
      PREVIEW_HIGHLIGHTS
    );
  }
}
