import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-hek-and-gcn",
  templateUrl: "./hek-and-gcn.component.html",
  styleUrls: ["./hek-and-gcn.component.scss"],
})
export class HekAndGcnComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  helioTabs: any[] = [{ tabName: "HEK" }, { tabName: "GCN" }];
  groupedData: any = [];
  selectedTab: any;
  eventData: any = [
    { code: "AR", name: "Active Region" },
    { code: "BP", name: "Bright Point" },
    { code: "AR", name: "Active Region" },
    { code: "BP", name: "Bright Point" },
    { code: "AR", name: "Active Region" },
    { code: "BP", name: "Bright Point" },
    { code: "AR", name: "Active Region" },
    { code: "BP", name: "Bright Point" },
  ];
  tableData: any = [
    {
      keyword: "AGILE",
      name: "Astro-rivelatore Gamma a Immagini Leggero",
      type: "Mission/Observatory",
    },
    {
      keyword: "ALMA",
      name: "Atacama Large Millimeter/submillimeter Array",
      type: "Mission/Observatory",
    },
    {
      keyword: "AMON",
      name: "Astrophysical Multimessenger Observatory Network",
      type: "Analysis/Alert System",
    },
    {
      keyword: "ANTARES",
      name: "Astronomy With a Neutrino Telescope and Abyss Environmental Research",
      type: "Mission/Observatory",
    },
    {
      keyword: "AXP",
      name: "Advanced X-ray Polarimeter",
      type: "Mission/Observatory",
    },
    {
      keyword: "Baksan Neutrino Observatory Alert",
      name: "Baksan Neutrino Observatory Alert",
      type: "Observatory Alert",
    },
    {
      keyword: "CALET",
      name: "Calorimetric Electron Telescope",
      type: "Mission/Observatory",
    },
    {
      keyword: "Chandra",
      name: "Chandra X-ray Observatory",
      type: "Mission/Observatory",
    },
    { keyword: "EP", name: "Einstein Probe", type: "Mission/Observatory" },
    {
      keyword: "Fermi",
      name: "Fermi Gamma-ray Space Telescope",
      type: "Mission/Observatory",
    },
    { keyword: "FXT", name: "Follow-up X-ray Telescope", type: "Instrument" },
  ];
  coordinateSystemsData: any = [
    [
      { system: "UTC-HGS-TOPO", name: "Heliographics Stonyhurst" },
      { system: "UTC-HPR-TOPO", name: "Helioprojective" },
    ],
    [
      { system: "UTC-HGC-TOPO", name: "Heliographic Carrington" },
      { system: "UTC-HCR-TOPO", name: "Helio-centric Radial" },
    ],
  ];
  ngOnInit(): void {
    this.groupedData = this.groupData(this.eventData);

    this.route.queryParams.subscribe((params) => {
      const tab = params["tab"];

      if (tab) {
        this.selectedTab = tab; // Set the tab from the URL
      } else {
        this.selectedTab = "HEK"; // Set a default tab if needed
        this.updateQueryParam(this.selectedTab); // Automatically add default tab to URL
      }
    });
  }

  updateQueryParam(tab: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { tab },
      queryParamsHandling: "merge", // Merge with other params if any
    });
  }

  selectCurrentTab(tab) {
    this.selectedTab = tab.tabName;
    this.updateQueryParam(tab.tabName);
  }

  groupData(data: any) {
    const half = Math.ceil(data.length / 2);
    return [data.slice(0, half), data.slice(half)];
  }
}
