export default {
  facet: {
    date: {
      custom: "Benutzerdefinierte Daten"
    },
    timeline: {
      noDataToDisplay: "Es werden keine Daten angezeigt."
    }
  }
}
