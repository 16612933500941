import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { PreviewService } from "@sinequa/components/preview";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { Query } from "@sinequa/core/app-utils";
import {
  NotificationsService,
  NotificationType,
} from "@sinequa/core/notification";
import { Record, Results } from "@sinequa/core/web-services";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { HoverHelpDirective } from "../../directives/hover-help/hover-help.directive";
import { googleAnalyticsConstants } from "../analytics/google-analytics.constant";
import { appConfig } from "../app-config/app-config";
import { HoverHelpSharedService } from "../services/hover-help-shared.service";
import { SharedCommonService } from "../services/shared-common.service";
import { SdeRecord } from "./../../model/extended.interface";

@Component({
  selector: "app-sde-common-results",
  templateUrl: "./sde-common-results.component.html",
  styleUrls: ["./sde-common-results.component.scss"],
  providers: [HoverHelpDirective],
})
export class SdeCommonResultsComponent implements OnInit, OnChanges {
  tabName: string;
  constructor(
    private cdRef: ChangeDetectorRef,
    private $gaService: GoogleAnalyticsService,
    private router: Router,
    protected modalService: BsModalService,
    protected ui: UIService,
    protected searchService: SearchService,
    protected previewService: PreviewService,
    protected notificationsService: NotificationsService,
    protected sharedCommonService: SharedCommonService,
    private hoverHelpSharedService: HoverHelpSharedService
  ) {
    this.sharedCommonService.resultsCardLoader = true;
  }

  openedDoc: any;
  public dialog: MatDialog;
  modalRef: BsModalRef;
  _showShortPreview: boolean;
  previewStyle: any;
  hoverHelpEnabled = false;
  hideNoDataTemplate: boolean;

  @Input() isDidYouMean: boolean;
  @Input() didYouMeanObj: any;
  @Input() isShortPreviewOpen: boolean;
  @Input() currentAppLabel: string = "";
  @Input() currentQuery: string = "";
  @Input() results: Results;
  @Input() neuralData: any;
  @Input() showSciX: boolean;
  @Input() sciXCount: number = 0;
  @Output() shortPreviewOpened: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() manualTabNavigation: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild("resultCardsContainer") resultContainer: ElementRef;
  enableAIAssistedSearch = appConfig.enableAIAssistedSearch;

  get showShortPreview(): boolean {
    if (this.openedDoc === undefined) {
      this._showShortPreview = false;
    } else {
      this._showShortPreview = true;
    }
    return this._showShortPreview;
  }

  set showShortPreview(value: boolean) {
    this._showShortPreview = value;
  }

  ngOnInit(): void {
    this.hoverHelpSharedService.hoverHelpEnabled$.subscribe((enabled) => {
      this.hoverHelpEnabled = enabled;
    });
    this.sharedCommonService.showMobileFilterButtonValue.subscribe((data) => {
      this.hideNoDataTemplate = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["isShortPreviewOpen"]) {
      this.isShortPreviewOpen = changes["isShortPreviewOpen"].currentValue;
      if (!this.isShortPreviewOpen) {
        this.closeDocument();
      }

      this.cdRef.detectChanges();
    }

    if (changes["results"] && changes["results"].currentValue) {
      this.sharedCommonService.resultsCardLoader = false;
    }
  }

  handleTabChange(tabChangeEvent: any) {
    this.closeDocument();
    this.setTabData(tabChangeEvent.name);
    this.manualTabNavigation.emit(true);
    // Trigger a search for the manually clicked tab
    this.searchService.query.tab = tabChangeEvent.name;
  }

  getWalkthroughLabel(): string {
    if (
      this.sharedCommonService.isInTdammApp() &&
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "4 - DATA TYPE TABS";
    } else if (
      (this.sharedCommonService.isInTdammApp() &&
        this.ui.screenSizeIsGreater("sm")) ||
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "5 - DATA TYPE TABS";
    } else if (!this.enableAIAssistedSearch) {
      return "6 - DATA TYPE TABS";
    } else {
      return "7 - DATA TYPE TABS";
    }
  }

  getWalkthroughLabelForResultCard(): string {
    if (
      (this.showSciX && this.ui.screenSizeIsLessOrEqual("xs")) ||
      (this.ui.screenSizeIsLessOrEqual("xs") &&
        this.sharedCommonService.isInTdammApp())
    ) {
      return "5 - RESULT CARD";
    } else if (
      (this.sharedCommonService.isInTdammApp() &&
        this.ui.screenSizeIsGreater("sm")) ||
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "6 - RESULT CARD";
    } else if (!this.enableAIAssistedSearch) {
      return "7 - RESULT CARD";
    } else {
      return "8 - RESULT CARD";
    }
  }

  setTabData(tabName: string) {
    this.sharedCommonService.resultsCardLoader = true;
    this.tabName = tabName;
  }

  onDocumentClicked(record: SdeRecord, event: Event) {
    if (record.flags.length >= 1) {
      this.isShortPreviewOpen = true;
      this.openedDoc = record;
      this.shortPreviewOpened.emit(true);

      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.card,
        googleAnalyticsConstants.label.clickResultsDocument,
        0,
        true,
        {
          app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
          page: this.sharedCommonService.getAppDetailsForGA().resultsPage,
          debug: "true",
          url: this.router.url,
          ...this.sharedCommonService.createDocumentEventDetailsObject(
            this.sharedCommonService.getAppDetailsForGA().app_name,
            record.title,
            record.collection,
            record.id,
            this.searchService.query.text,
            this.searchService.query.scope,
            this.sharedCommonService.getAppliedFilterValues()
          ),
        }
      );

      event.stopImmediatePropagation();
    }
  }

  /**
   * Responds to a click on a top passage - AI Assisted search (setting openedDoc will open the preview facet)
   * @param record
   * @param event
   */
  onDocumentClickedTopPassages(eventData: any) {
    let record = eventData.$record;
    if (record.flags.length >= 1) {
      this.isShortPreviewOpen = true;
      this.openedDoc = record;
      this.shortPreviewOpened.emit(true);
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.card,
        googleAnalyticsConstants.label.citedDocument,
        0,
        true,
        {
          app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
          page: this.sharedCommonService.getAppDetailsForGA().resultsPage,
          debug: "true",
          url: this.router.url,
          ...this.sharedCommonService.createDocumentEventDetailsObject(
            this.sharedCommonService.getAppDetailsForGA().app_name,
            record.title,
            record.collection,
            record.id,
            this.searchService.query.text,
            this.searchService.query.scope,
            this.sharedCommonService.getAppliedFilterValues()
          ),
        }
      );
      eventData.stopImmediatePropagation();
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edg") > -1:
        return "edge";
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        return "opera";
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        return "chrome";
      case agent.indexOf("trident") > -1:
        return "ie";
      case agent.indexOf("firefox") > -1:
        return "firefox";
      case agent.indexOf("safari") > -1:
        return "safari";
      default:
        return "other";
    }
  }

  openAstroTagsModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
  }

  getAstroTitleTags(tag: string): string {
    const parts = tag.split("/");

    if (parts.length === 3) {
      return `<span class="font-family-inter-semi-bold">${parts[1]}</span> <img src="assets/img/astro-chip-icon.svg" /> <span class="font-family-public-sans-regular">${parts[2]}</span>`;
    } else if (parts.length === 2) {
      return parts[1];
    }

    return tag;
  }

  getAstroTags(parent: string, tag?: string | null): string {
    if (tag) {
      return `<span class="font-family-inter-semi-bold">${parent}</span> <img src="assets/img/astro-chip-icon.svg" /> <span class="font-family-inter-regular">${tag}</span>`;
    } else {
      return parent;
    }
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  groupItems(sourcetree: string[]): { [key: string]: any[] } {
    return sourcetree.reduce((groups, item) => {
      const parts = item.split("/");
      if (parts.length === 3) {
        const parent = parts[1];
        if (!groups[parent]) {
          groups[parent] = [];
        }
        groups[parent].push(parts[2]);
      } else if (parts.length === 2) {
        const parent = parts[1];
        if (!groups[parent]) {
          groups[parent] = [];
        }
      }

      return groups;
    }, {} as { [key: string]: any[] });
  }

  /**
   * Open Full Page Document/Metadata Preview from result card
   */
  expandDocumentPreview(record: SdeRecord, event?: any) {
    this.openedDoc = record;

    let query: Query = this.searchService.query.copy();

    if (this.currentQuery === "tdamm_query_service") {
      this.previewService.openRoute(
        this.openedDoc as Record,
        query,
        "tdamm/preview"
      );
    } else {
      this.previewService.openRoute(this.openedDoc as Record, query, "preview");
    }

    // Added to keep filters open when coming back from full page preview
    if (event) event.stopPropagation();

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.button,
      googleAnalyticsConstants.label.openDocumentNavigator,
      0,
      true,
      {
        app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
        page: this.sharedCommonService.getAppDetailsForGA().previewPage,
        debug_mode: "true",
        url: this.router.url,
        ...this.sharedCommonService.createDocumentEventDetailsObject(
          this.sharedCommonService.getAppDetailsForGA().app_name,
          record.title,
          record.collection,
          record.id,
          this.searchService.query.text,
          this.searchService.query.scope,
          this.sharedCommonService.getAppliedFilterValues()
        ),
      }
    );
  }

  /**
   * Copy URL of Full Page Document/Metadata Preview from result card
   */
  copyURLDocumentPreview(record: Record, event: any, fullPagePreview = false) {
    if (this.currentQuery === "tdamm_query_service") {
      this.sharedCommonService.copyURL(
        record as Record,
        this.searchService.query,
        "tdamm/preview"
      );
    } else {
      this.sharedCommonService.copyURL(
        record as Record,
        this.searchService.query,
        "preview"
      );
    }

    this.notificationsService.notify(
      NotificationType.Success,
      "msg#actionMenu.urlCopiedToClipboard",
      {},
      undefined,
      true
    );

    event.stopPropagation();

    if (this.sharedCommonService.isOptionalGA4EventActive) {
      this.$gaService.event(
        googleAnalyticsConstants.action.click,
        googleAnalyticsConstants.category.button,
        googleAnalyticsConstants.label.copyDocumentLink,
        0,
        true,
        {
          app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
          page: this.sharedCommonService.getAppDetailsForGA().resultsPage,
          debug: "true",
          url: this.router.url,
          ...this.sharedCommonService.createDocumentEventDetailsObject(
            this.sharedCommonService.getAppDetailsForGA().app_name,
            record.title,
            record.collection,
            record.id,
            this.searchService.query.text,
            this.searchService.query.scope,
            this.sharedCommonService.getAppliedFilterValues()
          ),
        }
      );
    }
  }

  onPaginate() {
    this.closeDocument();
  }

  /**
   * Responds to the preview facet being closed by a user action
   */
  closeDocument() {
    this.isShortPreviewOpen = false;
    this.shortPreviewOpened.emit(false);
    if (this.openedDoc) {
      this.openedDoc = undefined;
    }
  }

  downloadDocument(record: SdeRecord) {
    this.sharedCommonService.downloadDocument(<SdeRecord>record);
  }
}
