<div class="my-search" [ngClass]="collapsed ? 'collapsed-view' : 'expanded-view'">
    <ng-container *ngFor="let item of items; index as i">
        <div *ngIf="!item.hidden"
            class="my-search-content sq-metadata-item sq-metadata-color-{{fields[i]}}"
            [ngClass]="{'sq-metadata-border': useBadges}">
            <span
                [innerHTML]="item.display | sqExpr:{withFields: displayFieldNames, asHTML: true}"
                title="{{fields[i]}}">
            </span>
            <span
                *ngIf="allowDeletion"
                class="far fa-times-circle ps-1"
                role="button"
                (click)="removeItem(item)"
                title="{{'msg#facet.mySearch.itemRemove' | sqMessage}}">
            </span>
        </div>
    </ng-container>
</div>
<div *ngIf="collapsible && !isEmpty">
    <hr>
    <i class="fas fa-chevron-circle-{{collapsed ? 'down' : 'up'}}" (click)="collapsed = !collapsed;"></i>
</div>
