import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { BsTabs, SearchService } from "@sinequa/components/search";
import { Tab } from "@sinequa/core/web-services";
import { DeviceDetectorService } from "ngx-device-detector";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { SharedCommonService } from "../../app/services/shared-common.service";
import googleAnalyticsConstants from "./../../app/analytics/google-analytics.constant";

@Component({
  selector: "app-sde-tabs",
  templateUrl: "./sde-tabs.component.html",
  styleUrls: ["./sde-tabs.component.scss"],
})
export class SdeTabsComponent extends BsTabs implements OnInit {
  constructor(
    protected override searchService: SearchService,
    private deviceService: DeviceDetectorService,
    private $gaService: GoogleAnalyticsService,
    private sharedCommonService: SharedCommonService,
    public router: Router
  ) {
    super(searchService);
  }

  visibleTabs: any;
  moreTabs: any;
  visibleTabSelected: any;
  moreTabClick: boolean = false;

  @Input() searchText: string;
  @Input() sciXCount: number;

  ngOnInit(): void {
    this.initializeTabs();
  }

  onMoreTabClick(selectedTab: any, checked: boolean): void {
    this.moreTabClick = checked;

    const lastVisibleTab = this.visibleTabs.pop();
    if (lastVisibleTab) {
      this.moreTabs = [...this.moreTabs, lastVisibleTab];
    }

    this.visibleTabs.push(selectedTab);
    this.moreTabs = this.moreTabs.filter((tab) => tab !== selectedTab);

    this.selectTab(selectedTab);
  }

  ngOnDestroy(): void {}

  override ngOnChanges(changes: SimpleChanges) {
    // Check if the inputData property has changed
    if (!!changes["results"]) {
      this.update();
    }

    this.searchService.queryStream.subscribe((stream) => {
      const searchText = stream?.text || null;
      this.handleQueryStream(searchText);
    });

    // Assign sciXCount to the count property of the "Publications" object

    const publications = this.searchtabs?.find(
      (item) => item.name === "Publications"
    );
    if (publications) {
      if (this.sharedCommonService.isSDEApp()) {
        publications.count = this.sciXCount ? this.sciXCount : 0;
      } else {
        this.sciXCount = 0;
        publications.count = 0;
      }
    }
  }

  override selectTab(tab: Tab, search = true) {
    this.visibleTabSelected = tab.name;

    this.$gaService.event(
      googleAnalyticsConstants.action.click,
      googleAnalyticsConstants.category.tab,
      tab?.name,
      0,
      true,
      {
        app_name: this.sharedCommonService.getAppDetailsForGA().app_name,
        page: this.sharedCommonService.getAppDetailsForGA().resultsPage,
        url: this.router.url,
        ...this.sharedCommonService.createSearchEventDetailsObject(
          this.searchService.query.text,
          this.sharedCommonService.selectedScientificFocus,
          this.sharedCommonService.getAppliedFilterValues()
        ),
      }
    );
    if (tab !== this.currentTab) {
      if (search) {
        this.searchService.selectTab(tab); // the currentTab will be updated in update()
      } else {
        this.currentTab = tab;
      }

      this.events.next(tab);
    }

    return false; // Stop following href
  }

  initializeTabs() {
    this.visibleTabs = this.searchtabs?.slice(1, 6) || [];
    this.moreTabs = this.searchtabs?.slice(6) || [];
    this.visibleTabSelected =
      this.searchService.query.tab ||
      (this.visibleTabs.length ? this.visibleTabs[0].name : null);
  }

  updateTabs() {
    this.visibleTabs = this.searchtabs?.slice(1, 6) || [];
    this.moreTabs = this.searchtabs?.slice(6) || [];
  }

  handleQueryStream(searchText: string | null) {
    if (!searchText) {
      this.moreTabClick = false;

      const publicationsTab = this.visibleTabs.find(
        (tab) => tab.name === "Publications"
      );
      if (publicationsTab) {
        const lastVisibleTab = this.visibleTabs.pop();
        if (lastVisibleTab) {
          this.moreTabs = [...this.moreTabs, lastVisibleTab];
        }

        const missionTab = this.moreTabs.find(
          (tab) => tab.name === "Missions and Instruments"
        );
        if (missionTab) {
          this.visibleTabs.push(missionTab);
          this.visibleTabSelected = "Missions and Instruments";
        }
      }

      if (!this.moreTabClick) {
        this.moreTabs = [...(this.searchtabs?.slice(6) ?? [])];
      }
    } else {
      if (!this.moreTabClick) {
        this.moreTabs = [...(this.searchtabs?.slice(6) ?? [])];
      }
    }
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  getTabTooltip(tab: any): string {
    const formattedCount = new Intl.NumberFormat().format(tab.count); // Formats with commas
    return `${tab.name} (${formattedCount})`;
  }

  isTabDisabled(tab: any): boolean {
    // Disable the tab if its name is "Publications" and searchText is empty

    if (this.sharedCommonService.isSDEApp()) {
      if (
        tab.name === "Publications" &&
        (!this.searchText || this.searchText.trim() === "")
      ) {
        this.sciXCount = 0;
        return true;
      } else {
        return false;
      }
    } else {
      if (tab.name === "Publications") {
        return true;
      }
      return false;
    }
  }
}
