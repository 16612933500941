import { Component, Input, OnInit } from "@angular/core";
import { SearchService } from "@sinequa/components/search";
import { UIService } from "@sinequa/components/utils";
import { SharedCommonService } from "../services/shared-common.service";

@Component({
  selector: "app-mobile-filter-panel",
  templateUrl: "./mobile-filter-panel.component.html",
  styleUrls: ["./mobile-filter-panel.component.scss"],
})
export class MobileFilterPanelComponent implements OnInit {
  constructor(
    protected ui: UIService,
    protected searchService: SearchService,
    protected sharedCommonService: SharedCommonService
  ) {}

  public hasFacets: boolean = true;
  hideFilterTab: boolean = false;

  @Input() results: any;
  @Input() showSciX: boolean;
  @Input() showSciXMetadata: boolean;

  ngOnInit(): void {}

  toggleFiltersForMobile() {
    this.hideFilterTab = !this.hideFilterTab;
    this.sharedCommonService.showMobileFilterButton(this.hideFilterTab);
  }

  getWalkthroughLabelForFilterMobile(): string {
    if (
      this.sharedCommonService.isInTdammApp() &&
      this.ui.screenSizeIsLessOrEqual("xs")
    ) {
      return "3 - FILTERS";
    } else {
      return "4 - FILTERS";
    }
  }
}
