import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { EllipsisModule } from "ngx-ellipsis";
import { NestedEllipsisModule } from "ngx-nested-ellipsis";
import { SdeEventBasedMetadataViewerComponent } from "./sde-event-based-metadata-viewer.component";

import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { BsNotificationModule } from "@sinequa/components/notification";
import { UtilsModule } from "@sinequa/components/utils";
import { IntlModule } from "@sinequa/core/intl/intl.module";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TabsModule, TabsetConfig } from "ngx-bootstrap/tabs";
import { NgxMasonryModule } from "ngx-masonry";
import { SharedCommonService } from "../services/shared-common.service";

@NgModule({
  exports: [
    CommonModule,
    PopoverModule,
    TabsModule,
    BsDropdownModule,
    FontAwesomeModule,
    SdeEventBasedMetadataViewerComponent,
  ],
  declarations: [SdeEventBasedMetadataViewerComponent],
  providers: [TabsetConfig, BsDropdownConfig, SharedCommonService, DatePipe],

  imports: [
    CommonModule,
    PopoverModule,
    TabsModule,
    BsDropdownModule,
    FontAwesomeModule,
    FormsModule,
    NgxMasonryModule,
    UtilsModule,
    EllipsisModule,
    NestedEllipsisModule,
    ModalModule.forRoot(),
    IntlModule,
    BsNotificationModule,
  ],
})
export class SdeEventBasedMetadataViewerModule {}
