import { Component, OnInit, SimpleChanges } from "@angular/core";
import { FiltersComponent } from "@sinequa/components/filters/filters.component";
import { UIService } from "@sinequa/components/utils";
import { AppService } from "@sinequa/core/app-utils";
import { Utils } from "@sinequa/core/base";
import { SharedCommonService } from "../../app/services/shared-common.service";

@Component({
  selector: "app-sde-applied-filters-list",
  templateUrl: "./sde-applied-filters-list.component.html",
  styleUrls: ["./sde-applied-filters-list.component.scss"],
})
export class SdeAppliedFiltersListComponent
  extends FiltersComponent
  implements OnInit
{
  constructor(
    public override appService: AppService,
    public ui: UIService,
    private sharedCommonService: SharedCommonService
  ) {
    super(appService);
  }

  ngOnInit(): void {}

  tdammFilters: string[] = ["Messengers", "Objects", "Signals"];

  override remove() {
    this.query.removeFilters((f) => f === this.filter);
    this.filterEdit.emit(this.query);
    if (this.query.getFilterCount(undefined) === 0) {
      this.sharedCommonService.modalRef?.hide();
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter) {
      this.exprFilter = undefined;
      this.valueFilter = undefined;
      this.betweenFilter = undefined;
      this.inFilter = undefined;
      this.nullFilter = undefined;
      this.field = undefined;
      this.operator = undefined;
      if (this.filter) {
        switch (this.filter.operator) {
          case "and":
          case "or":
          case "not":
            this.exprFilter = this.filter;
            break;
          case "between":
            this.betweenFilter = this.filter;
            break;
          case "in":
            this.inFilter = this.filter;
            break;
          case "null":
            this.nullFilter = this.filter;
            break;
          default:
            this.valueFilter = this.filter;
        }

        const field = this.getField(this.filter);

        if (field) {
          this.column = this.appService.getColumn(field);
          if (
            this.filter.hasOwnProperty("value") &&
            this.isTDAMMFiltersTreepathPresent((<any>this.filter).value)
          ) {
            this.field = Utils.treeFirstNode((<any>this.filter).value);
          } else {
            this.field = this.exprFilter
              ? this.appService.getPluralLabel(field)
              : this.appService.getSingularLabel(field);
          }
        }

        // Get the operator displayed in front of the value
        this.operator =
          this.exprFilter || this.filter.display || !this.filter.operator
            ? "eq"
            : this.filter.operator;
      }
    }
  }

  isTDAMMFiltersTreepathPresent(treepath: string) {
    return this.tdammFilters.includes(Utils.treeFirstNode(treepath));
  }
}
