export default {
  facet: {
    date: {
      custom: "Custom Date Range"
    },
    timeline: {
      noDataToDisplay: "No data to display."
    }
  }
}
