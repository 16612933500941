import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { BrowserModule } from "@angular/platform-browser";
import { BsActionModule } from "@sinequa/components/action";
import { CollapseModule } from "@sinequa/components/collapse/collapse.module";
import { BsLabelsModule } from "@sinequa/components/labels";
import { BsNotificationModule } from "@sinequa/components/notification";
import { ResultModule } from "@sinequa/components/result";
import { BsSearchModule } from "@sinequa/components/search";
import { SCREEN_SIZE_RULES, UtilsModule } from "@sinequa/components/utils";
import { IntlModule, Locale, LocalesConfig } from "@sinequa/core/intl";
import { BsDropdownConfig, BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TabsModule } from "ngx-bootstrap/tabs";
import { JoyrideModule } from "ngx-joyride";
import { HoverHelpDirective } from "../directives/hover-help/hover-help.directive";
import { InheritedModule } from "../inherited/inherited.module";
import { SdeSearchFormComponent } from "../inherited/sde-search-form/sde-search-form.component";
import deLocale from "../locales/de";
import enLocale from "../locales/en";
import frLocale from "../locales/fr";
import { SdeCommonContactUsPageComponent } from "./../app/sde-common-contact-us-page/sde-common-contact-us-page.component";
import { CommonFullPreviewModule } from "./../app/sde-common-full-preview/sde-common-full-preview.module";
import { SdeCommonHelpSupportModalComponent } from "./../app/sde-common-help-support-modal/sde-common-help-support-modal.component";
import { SdeNotificationComponent } from "./../inherited/sde-notification/sde-notification.component";
import { SdeNotificationsComponent } from "./../inherited/sde-notifications/sde-notifications.component";
import { AppComponent } from "./app.component";
import { FiltersToolbarComponent } from "./filters-toolbar/filters-toolbar.component";
import { HekAndGcnComponent } from "./hek-and-gcn/hek-and-gcn.component";
import { LandscapeViewPageComponent } from "./landscape-view-page/landscape-view-page.component";
import { MobileFilterPanelComponent } from "./mobile-filter-panel/mobile-filter-panel.component";
import { MobileViewComponent } from "./mobile-view/mobile-view.component";
import { NoDataFoundComponent } from "./no-data-found/no-data-found.component";
import { SdeCommonHeaderComponent } from "./sde-common-header/sde-common-header.component";
import { SdeCommonNasaLoaderComponent } from "./sde-common-nasa-loader/sde-common-nasa-loader.component";
import { SdeCommonResultsComponent } from "./sde-common-results/sde-common-results.component";
import { SdeCommonShortPreviewComponent } from "./sde-common-short-preview/sde-common-short-preview.component";
import { SdeFiltersModalComponent } from "./sde-filters-modal/sde-filters-modal.component";
import { SdeSearchFacetsComponent } from "./sde-search-facets/sde-search-facets.component";
import { AutocompleteComponent } from "./search-form/autocomplete/autocomplete.component";
import { SdeSearchComponent } from "./search-form/search-form.component";

// Screen size breakpoints (consistent with Bootstrap custom breakpoints in app.scss)
export const breakpoints = {
  xl: "(min-width: 1650px)",
  lg: "(min-width: 1400px) and (max-width: 1649.98px)",
  md: "(min-width: 992px) and (max-width: 1399.98px)",
  sm: "(min-width: 576px) and (max-width: 991.98px)",
  xs: "(max-width: 575.98px)",
};

export class AppLocalesConfig implements LocalesConfig {
  defaultLocale: Locale;
  locales?: Locale[];
  constructor() {
    this.locales = [
      { name: "en", display: "msg#locale.en", data: enLocale },
      { name: "fr", display: "msg#locale.fr", data: frLocale },
      { name: "de", display: "msg#locale.de", data: deLocale },
    ];
    this.defaultLocale = this.locales[0];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SdeCommonResultsComponent,
    SdeCommonHeaderComponent,
    AutocompleteComponent,
    SdeSearchFormComponent,
    SdeCommonShortPreviewComponent,
    SdeSearchComponent,
    SdeSearchFacetsComponent,
    FiltersToolbarComponent,
    SdeCommonHelpSupportModalComponent,
    SdeCommonContactUsPageComponent,
    SdeFiltersModalComponent,
    SdeNotificationComponent,
    SdeNotificationsComponent,
    NoDataFoundComponent,
    MobileViewComponent,
    LandscapeViewPageComponent,
    HoverHelpDirective,
    MobileFilterPanelComponent,
    SdeCommonNasaLoaderComponent,
    HekAndGcnComponent,
  ],
  imports: [
    BrowserModule,
    BsDropdownModule,
    ResultModule,
    BsLabelsModule,
    TabsModule,
    UtilsModule,
    BsActionModule,
    BsSearchModule,
    IntlModule.forRoot(AppLocalesConfig),
    CommonFullPreviewModule,
    InheritedModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    BsActionModule,
    CollapseModule,
    BsSearchModule,
    UtilsModule,
    BsNotificationModule,
    JoyrideModule.forRoot(),
  ],
  exports: [
    SdeCommonResultsComponent,
    SdeCommonHeaderComponent,
    SdeCommonShortPreviewComponent,
    SdeSearchFacetsComponent,
    FiltersToolbarComponent,
    SdeCommonHelpSupportModalComponent,
    SdeCommonContactUsPageComponent,
    SdeFiltersModalComponent,
    SdeNotificationComponent,
    SdeNotificationsComponent,
    NoDataFoundComponent,
    MobileViewComponent,
    LandscapeViewPageComponent,
    MobileFilterPanelComponent,
    HekAndGcnComponent,
    HoverHelpDirective,
    InheritedModule,
    SdeSearchComponent,
    AutocompleteComponent,
    SdeCommonNasaLoaderComponent,
  ],
  providers: [
    { provide: SCREEN_SIZE_RULES, useValue: breakpoints },
    BsDropdownConfig,
  ],
  bootstrap: [AppComponent],
})
export class CommonComponentsModule {}
