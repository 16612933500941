<div class="helio-container">
  <div>
    <app-sde-common-header></app-sde-common-header>
  </div>

  <div class="nav-header">
    <i
      class="fa fa-chevron-left px-2 back-button-icon ms-4"
      title="{{ 'msg#search.showFilters' | sqMessage }}"
    ></i>
  </div>

  <tabset #tabset class="navbar-sde-tabs helio-tab-container">
    <tab
      *ngFor="let tab of helioTabs"
      [heading]="tab.tabName"
      [active]="tab.tabName === selectedTab"
      (selectTab)="selectCurrentTab(tab)"
    >
      <ng-container *ngIf="tab.tabName === 'HEK'">
        <ng-container *ngTemplateOutlet="heckTabContent"></ng-container>
      </ng-container>
      <ng-container *ngIf="tab.tabName === 'GCN'">
        <ng-container *ngTemplateOutlet="gcnTabContent"></ng-container>
      </ng-container>
    </tab>
  </tabset>
</div>

<ng-template #heckTabContent>
  <div class="tabs-content">
    <div class="top-section">
      <div class="title font-family-rajdhani-medium">
        <span class="font-family-rajdhani-bold me-2">HEK</span>(Heliophysics
        Events Knowledgebase)
      </div>
      <p class="font-family-rajdhani-medium">
        NASA’s Heliophysics Events Knowledgebase (HEK) is a comprehensive,
        searchable database that catalogs events and features observed on the
        Sun and throughout the heliosphere. Each event in HEK is classified with
        an Event Code corresponding to a specific Event Type:
      </p>
    </div>
    <div class="event-code-container">
      <div class="event-code-column" *ngFor="let eventGroup of groupedData">
        <table>
          <thead class="font-family-rajdhani-bold">
            <tr>
              <th>Event Code</th>
              <th>Event Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let event of eventGroup"
              class="font-family-rajdhani-medium"
            >
              <td>{{ event.code }}</td>
              <td>{{ event.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <p class="font-family-rajdhani-medium">
        The coordinate systems used in HEK for reporting events and features
        include:
      </p>
      <div class="coordinate-systems">
        <div
          class="coordinate-table"
          *ngFor="let table of coordinateSystemsData"
        >
          <table>
            <thead class="font-family-rajdhani-bold">
              <tr>
                <th>Coordinate System</th>
                <th>Coordinate System Name</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of table" class="font-family-rajdhani-medium">
                <td>{{ row.system }}</td>
                <td>{{ row.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div>
      <p class="font-family-rajdhani-medium">
        The instruments utilized in HEK for observing and detecting events and
        features include, but are not limited to:
      </p>
      <div class="event-code-container">
        <div class="event-code-column" *ngFor="let eventGroup of groupedData">
          <table>
            <thead class="font-family-rajdhani-bold">
              <tr>
                <th>Instrument</th>
                <th>Instrument Name</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let event of eventGroup"
                class="font-family-rajdhani-medium"
              >
                <td>{{ event.code }}</td>
                <td>{{ event.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div>
        <p class="font-family-rajdhani-medium">
          The instruments in HEK used to observe and detect events come from
          various missions and observatories, including, but not limited to:
        </p>
        <div class="event-code-container">
          <div class="event-code-column" *ngFor="let eventGroup of groupedData">
            <table>
              <thead class="font-family-rajdhani-bold">
                <tr>
                  <th>Mission/Obsservatory</th>
                  <th>Mission/Obsservatory Name</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let event of eventGroup"
                  class="font-family-rajdhani-medium"
                >
                  <td>{{ event.code }}</td>
                  <td>{{ event.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <p class="font-family-rajdhani-medium">
          To search for events and features by event codes, event attributes,
          instruments, missions, time, or other variables, visit the iSolSearch
          search interface on the HEK website.
        </p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #gcnTabContent>
  <div class="tabs-content">
    <div class="top-section">
      <div class="title font-family-rajdhani-medium">
        <span class="font-family-rajdhani-bold me-2">GCN</span>(Global
        Coordinates Network)
      </div>
      <p class="font-family-rajdhani-medium">
        Global Coordinates Network (GCN) Circulars are a way for members of the
        Astrophysics community to quickly share discoveries, observations, and
        short-term predictions related to astrophysical events. In the SDE
        schema of circulars, subject keywords are provided within the Title and
        Event Type fields to inform the community about the content described by
        the circular. These keywords include, but are not limited to, the
        following:
      </p>
    </div>
    <div class="custom-table-container">
      <table class="custom-table">
        <thead class="font-family-rajdhani-bold">
          <tr>
            <th>Keyword</th>
            <th>Name</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let item of tableData"
            class="font-family-rajdhani-medium"
          >
            <td>{{ item.keyword }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p class="font-family-rajdhani-medium">
      Additionally, Event IDs correspond to an incremental value assigned by
      GCN. Each GCN submitted by the community  increases the Event ID by 1. To
      search by time, event IDs, or keywords, or to submit a new event, please
      visit the GCN Circulars webpage.
    </p>
  </div>
</ng-template>
