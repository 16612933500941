<!-- Navbar with app icon, search form, and menus -->
<nav
  class="navbar navbar-expand-sm p-2"
  role="presentation"
  [ngClass]="{
    'navbar-bg-all': isResults() && currentSciFocus == 'All',
    'navbar-bg-earth-science':
      isResults() && currentSciFocus == 'Earth Science',
    'navbar-bg-planetary':
      isResults() && currentSciFocus == 'Planetary Science',
    'navbar-bg-astrophysics':
      isResults() &&
      currentSciFocus == 'Astrophysics' &&
      !sharedCommonService.isInTdammApp(),
    'navbar-bg-bio-physical':
      isResults() && currentSciFocus == 'Biological & Physical Sciences',
    'navbar-bg-heliophysics': isResults() && currentSciFocus == 'Heliophysics',
    'navbar-mobile-height': isMobile && !isHome()
  }"
  [ngStyle]="
    sharedCommonService.isTdammResults() ? setTdammBackgroundStyle : {}
  "
>
  <div
    class="container-fluid"
    [ngClass]="{ 'header-container-mobile': ui.screenSizeIsLessOrEqual('xs') }"
  >
    <!-- Application logo -->
    <div class="nasa-home-container">
      <a
        role="presentation"
        [href]="navGovUrl"
        target="_blank"
        [ngClass]="{
          'animate-fade-out hide-topIcons': readMoreMode === 'enabled',
          'animate-fade-in': readMoreMode === 'disabled'
        }"
      >
        <img
          width="75px"
          [ngClass]="{ 'nasa-logo': isHome() }"
          id="logo"
          class="me-2"
          src="assets/img/nasa-meatball.svg"
          alt="NASA meatball logo"
        />
      </a>
      <div
        *ngIf="
          isResults() || sharedCommonService.isTdammResults() || isHekAndGcn()
        "
      >
        <img
          [src]="
            isHekAndGcn()
              ? 'assets/img/vertical-vector-dark.svg'
              : 'assets/img/vertical-vector.svg'
          "
          alt="vertical-vector"
        />
      </div>
      <div (click)="navigateToRoutes()">
        <img
          *ngIf="
            isResults() || sharedCommonService.isTdammResults() || isHekAndGcn()
          "
          class="nasa-home-icon"
          [src]="getImageSrc()"
          [alt]="getImageAlt()"
        />
      </div>
    </div>
    <div
      *ngIf="isHome()"
      [ngStyle]="{ position: 'absolute', left: getWidth() }"
    >
      <div
        *ngIf="this.ui.screenSizeIsGreaterOrEqual('md')"
        class="homepage-user-menu-bootstrap"
        id="homepage-user-info"
      ></div>
    </div>
    <div
      *ngIf="sharedCommonService.isTdammResults()"
      class="beta-text-search font-family-rajdhani-medium"
    >
      BETA
    </div>
    <h2 class="help-text" *ngIf="isHelp()">HELP</h2>

    <div
      [ngClass]="{
        show: hoverHelpEnabled
      }"
      [hoverHelpEnabled]="hoverHelpEnabled"
      appTooltip="Select the scientific focus area of your search. Only results included in that focus area will appear below.
      "
      class="sde-text font-family-rajdhani-regular"
      joyrideStep="ninethStep"
      [stepContent]="customContentNineth"
      *ngIf="
        showForm &&
        !isMinimal! &&
        !isMobile &&
        !sharedCommonService.isInTdammApp()
      "
      class="sci-focus-group btn-group"
      dropdown
      #dropdown="bs-dropdown"
      [autoClose]="false"
      (isOpenChange)="dropdownToggled($event)"
    >
      <button
        id="button-basic"
        dropdownToggle
        (outsideClick)="handleOutsideClick()"
        type="button"
        class="sci-focus-btn-closed btn btn-primary"
        [ngClass]="
          isSciFocusDropdownActive
            ? 'sci-focus-btn-opened'
            : 'sci-focus-btn-closed'
        "
        aria-controls="dropdown-basic"
      >
        <img
          src="{{ getIconForScientificFocus(currentSciFocus) }}"
          class="sci-focus-icon"
          alt="{{ currentSciFocus }}"
        />
        <div
          class="selected-sci-focus"
          [ngClass]="{
            'infinite-animate-selected':
              currentSciFocus === 'Biological & Physical Sciences' ||
              currentSciFocus === 'Planetary Science'
          }"
        >
          <p>{{ currentSciFocus }}</p>
        </div>
        <i class="fas down-arrow-icon fa-chevron-down"></i>
      </button>
      <ul
        id="dropdown-basic"
        *dropdownMenu
        class="sci-focus-dropdown dropdown-menu"
        role="presentation"
        aria-labelledby="button-basic"
      >
        <li role="presentation" *ngFor="let sciFocus of sciFocusList">
          <div
            *ngIf="sciFocus.name !== currentSciFocus"
            (click)="selectScientificFocus(sciFocus.name, dropdown)"
            class="sci-focus-wrapper"
            [sqTooltip]="sciFocus.name"
            tabindex="0"
          >
            <div class="sci-img-wrapper">
              <img [src]="sciFocus.icon" alt="{{ sciFocus.name }}" />
            </div>
            <div
              class="sci-name-wrapper overflow-hidden infinite-animate"
              [ngClass]="{
                'infinite-animate':
                  sciFocus.name === 'Biological & Physical Sciences' ||
                  sciFocus.name === 'Planetary Science'
              }"
            >
              <span
                role="link"
                class="sci-focus-item dropdown-item"
                [attr.aria-label]="getAriaLabel(sciFocus.name)"
              >
                {{ sciFocus.name }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ng-template #customContentNineth>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          style="color: rgb(2, 61, 139)"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{ getWalkthroughLabelForSearchFocus() }}
        </div>
      </div>
      <p class="joyride-content">
        This dropdown menu includes options to filter results by Scientific
        Focus Area (Earth Science, Planetary Science, Astrophysics, Biological
        and Physical Sciences, Heliophysics, or All).
      </p>
    </ng-template>
    <!-- Search form -->
    <app-search-form
      [prevTemplate]="prevButton"
      [nextTemplate]="nextButton"
      [stepContent]="customContentSeventh"
      [searchRoute]="source === 'TDAMM' ? 'tdamm/search' : 'search'"
      [ngClass]="{ show: hoverHelpEnabled }"
      [hoverHelpEnabled]="hoverHelpEnabled"
      appTooltip="The search bar displays the search query text. Click on the search bar to edit the query or begin a new search.
      "
      class="sde-text font-family-rajdhani-regular flex-grow-1 flex-basis-0 mx-3"
      joyrideStep="seventhStep"
      *ngIf="
        (showForm && !isMinimal && !isMobile) ||
        (sharedCommonService.isTdammResults() &&
          !ui.screenSizeIsLessOrEqual('xs'))
      "
    ></app-search-form>
    <ng-template #customContentSeventh>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          style="color: rgb(2, 61, 139)"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          1 - SEARCH BAR
        </div>
      </div>
      <p class="joyride-content">
        The search bar displays the search query text. Click on the search bar
        to edit the query or begin a new search.
      </p>
    </ng-template>
    <ng-template #prevButton>
      <img
        style="cursor: pointer"
        id="close-icon"
        width="45"
        height="45"
        src="assets/img/walkthrough-previous.svg"
      />
    </ng-template>
    <ng-template #nextButton>
      <img
        style="cursor: pointer"
        id="close-icon"
        width="45"
        height="45"
        src="assets/img/walkthrough-next.svg"
      />
    </ng-template>
    <!-- search bar for acronym finder -->
    <form
      novalidate
      [formGroup]="form"
      *ngIf="
        (showForm && !isMinimal && !isMobile) ||
        (sharedCommonService.isTdammResults() && !isMobile)
      "
      [ngClass]="{ show: hoverHelpEnabled }"
      [hoverHelpEnabled]="hoverHelpEnabled"
      appTooltip="Search for the expanded versions of acronyms.
      "
      class="sde-text font-family-inter-light"
    >
      <div
        class="acronym-form-div"
        joyrideStep="tenthStep"
        [stepContent]="customContentTenth"
      >
        <input
          type="text"
          [formControl]="searchControl"
          [placeholder]="'msg#acronym.input.placeholder' | sqMessage"
          class="acronym-form-control"
          [ngClass]="{
            'acronym-form-control-suggestions':
              acronymSearchText !== '' && acronymData?.length > 0
          }"
          (input)="searchAcronym()"
        />
        <i
          class="fa-solid fa-magnifying-glass acronym-search-icon-position"
        ></i>
        <i
          *ngIf="searchControl?.value"
          (click)="clearAcronymInput()"
          class="fa-solid fa-close acronym-close-icon-position pe-auto"
        ></i>
        <div
          *ngIf="acronymData?.length > 0 && acronymSearchText?.length >= 2"
          class="acronym-finder-suggestions"
        >
          <ul class="no-bullets">
            <li *ngFor="let item of acronymData">
              <div class="short-form">
                {{ item.sourcestr1 }}
              </div>
              <div class="long-form">
                {{ item.sourcestr2 }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </form>
    <ng-template #customContentTenth>
      <div class="joyride-step-header">
        <div
          class="joyride-step-title"
          style="color: rgb(2, 61, 139)"
          [ngClass]="{
            'w-75': ui.screenSizeIsLessOrEqual('sm'),
            'w-100': ui.screenSizeIsGreater('sm')
          }"
        >
          {{ getWalkthroughLabel() }}
        </div>
      </div>

      <p class="joyride-content">
        This search bar allows you to search for acronyms that appear in SDE
        results. Options will automatically populate as you type.
      </p>
    </ng-template>
    <!-- end of search bar for acronym finder -->
    <div class="flex-container">
      <div
        *ngIf="isHome()"
        class="mr-16"
        [ngClass]="{
          'animate-fade-out hide-topIcons': readMoreMode === 'enabled',
          'animate-fade-in': readMoreMode === 'disabled',
          ' ': readMoreMode === 'initial'
        }"
      >
        <a
          [href]="getTdammAppRoute()"
          (click)="navigateToTdammApp()"
          target="_blank"
          tabindex="0"
          role="button"
        >
          <img
            [ngClass]="{
              'help-and-support-icon': isHome(),
              'mobile-size': ui.screenSizeIsLessOrEqual('xs')
            }"
            src="assets/img/go-to-tdamm-icon.svg"
            sqTooltip="{{ 'msg#tdammApp.name' | sqMessage }}"
            alt="Click here to access an SDE interface designed exclusively for accessing time domain and multi-messenger astronomy (TDAMM)."
          />
        </a>
      </div>
      <div
        *ngIf="isHome()"
        appTooltip="Click here to access an SDE interface designed exclusively for accessing environmental justice data."
        [ngClass]="{
          'animate-fade-out hide-topIcons': readMoreMode === 'enabled',
          'animate-fade-in': readMoreMode === 'disabled',
          ' ': readMoreMode === 'initial'
        }"
        class="help-icon-container mr-16"
      >
        <ul
          class="navbar-nav navbar-right help-support-icon"
          role="presentation"
        >
          <!-- <li>
            <a
              class="ej-icon-link"
              [href]="getEJAppRoute()"
              (click)="navigateToEjApp()"
              target="_blank"
              tabindex="0"
              role="button"
            >
              <img
                [ngClass]="{
                  'ej-icon': isHome(),
                  'mobile-size': ui.screenSizeIsLessOrEqual('xs')
                }"
                src="assets/img/ej-logo-icon.svg"
                sqTooltip="{{ 'msg#secondaryApp.name' | sqMessage }}"
                alt="Click here to access an SDE interface designed exclusively for accessing environmental justice data."
                width="55"
              />
            </a>
          </li> -->
        </ul>
      </div>
      <div *ngIf="sharedCommonService.isTdammHome()" class="go-to-sde-icon">
        <a
          (click)="navigateToSdeApp()"
          sqTooltip="Go to SDE"
          [href]="getSdeAppUrl()"
          target="_blank"
        >
          <img
            class="go-to-sde-img"
            src="assets/img/sde-new-logo.svg"
            alt="Go to SDE"
          />
        </a>
      </div>
      <div
        tabindex="0"
        role="button"
        *ngIf="source == 'SDE' || source == 'TDAMM'"
        appTooltip="Click the question mark icon to access help and support resources like FAQ and the feedback form.
        "
        class="help-icon-container"
        [ngClass]="{
          'animate-fade-out hide-topIcons': readMoreMode === 'enabled',
          'animate-fade-in': readMoreMode === 'disabled',
          ' ': readMoreMode === 'initial',
          show: hoverHelpEnabled
        }"
        [hoverHelpEnabled]="hoverHelpEnabled"
        (keyup.enter)="detectHelpandSupportEvent($event)"
      >
        <ul
          class="navbar-nav navbar-right help-support-icon"
          role="presentation"
        >
          <li>
            <img
              [ngClass]="{
                'help-and-support-icon': isHome(),
                'mobile-size': ui.screenSizeIsLessOrEqual('xs')
              }"
              (click)="
                sharedCommonService.isTdammHome()
                  ? openHelpAndSupportModalFromResultsPage('tdamm')
                  : openHelpAndSupportModalFromResultsPage('results')
              "
              *ngIf="
                (!isHelp() && !isMinimal) || sharedCommonService.isInTdammApp();
                else elseContainer
              "
              [src]="generateSrcForTDAMM()"
              sqTooltip="{{ 'msg#general.helpIconText' | sqMessage }}"
              alt="The question mark icon can help you access the SDE help and support resources like FAQ and the feedback form."
              width="55"
            />
            <ng-template #elseContainer>
              <!-- Using help icon with white background for Earth Day -->
              <img
                [ngClass]="{
                  'help-and-support-icon': isHome(),
                  'mobile-size': ui.screenSizeIsLessOrEqual('xs')
                }"
                (click)="openHelpAndSupportModalFromHomePage('home')"
                joyrideStep="sixthStep"
                [stepContent]="customContentSixth"
                src="assets/img/help.svg"
                sqTooltip="{{ 'msg#general.helpIconText' | sqMessage }}"
                alt="The question mark button can help you access the SDE help and support resources like FAQ and the feedback form."
                width="55"
              />
            </ng-template>
            <ng-template #customContentSixth>
              <div class="joyride-step-header">
                <div
                  class="joyride-step-title"
                  style="color: rgb(2, 61, 139)"
                  [ngClass]="{
                    'w-75': ui.screenSizeIsLessOrEqual('sm'),
                    'w-100': ui.screenSizeIsGreater('sm')
                  }"
                >
                  {{
                    enableAIAssistedSearch
                      ? "5 - HELP AND SUPPORT"
                      : "4 - HELP AND SUPPORT"
                  }}
                </div>
              </div>

              <p class="joyride-content">
                Click the question mark icon to access help and support
                resources like FAQ and a feedback form.
              </p>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- Navbar with app icon, search form, and menus -->
<nav class="navbar navbar-expand-sm p-0">
  <div class="container-fluid" class="navbar-menu">
    <ul
      *ngIf="showMenu"
      class="navbar-nav navbar-right"
      [ngClass]="{ 'full-width': ui.screenSizeIsLess('sm') }"
    ></ul>
  </div>
</nav>
