import { Component, Input } from "@angular/core";
import { BsDidYouMean } from "@sinequa/components/search/bootstrap/did-you-mean/did-you-mean";
import { SearchService } from "@sinequa/components/search/search.service";
import {
  DidYouMeanItem,
  DidYouMeanKind,
  Results,
} from "@sinequa/core/web-services";
import { SharedCommonService } from "../../app/services/shared-common.service";

@Component({
  selector: "app-sde-did-you-mean",
  templateUrl: "./sde-did-you-mean.component.html",
  styleUrls: ["./sde-did-you-mean.component.scss"],
})
export class SdeDidYouMeanComponent extends BsDidYouMean {
  @Input() spellingCorrectionMode: string = "DYMOnly";
  @Input() override results: Results;

  override item: DidYouMeanItem | undefined;

  constructor(
    public override searchService: SearchService,
    public sharedCommonService: SharedCommonService
  ) {
    super(searchService);
  }

  override selectOriginal() {
    if (this.item) {
      this.searchService.didYouMean(
        this.item.original,
        DidYouMeanKind.Original
      );
    }
    this.sharedCommonService.showDidYouMean = false;
    return false;
  }

  override selectCorrected(): boolean {
    return false;
  }
}
