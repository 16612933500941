<div
  class="container-story"
  [ngClass]="{ 'carousel-buttons': ui.screenSizeIsGreater('sm') }"
>
  <carousel
    [interval]="currentInterval"
    (activeSlideChange)="onSlideChange($event)"
  >
    <ng-container *ngFor="let searchStory of searchStoryData; let i = index">
      <ng-container *ngFor="let storyItems of searchStory.storyItems">
        <slide>
          <div class="slide-container">
            <img
              *ngIf="ui.screenSizeIsGreater('sm')"
              [src]="setImagesForStory(storyItems.background)"
              alt="image slide"
              class="slide-bg"
            />
            <img
              *ngIf="ui.screenSizeIsLessOrEqual('xs')"
              [src]="setImagesForStory(storyItems.backgroundMobile)"
              alt="image slide"
              class="slide-bg"
            />
            <img
              [src]="setImagesForStory(searchStory.profilePicture)"
              alt="profile_picture"
              [ngClass]="{
                'profile-pic-mobile': ui.screenSizeIsLessOrEqual('xs'),
                'profile-pic': ui.screenSizeIsGreater('sm'),
                'hide-profile':
                  ui.screenSizeIsGreaterOrEqual('xs') &&
                  (searchStory.author === 'Eli Walker' ||
                    searchStory.author === 'BC Ray') &&
                  readMoreState[i]
              }"
            />
            <div
              *ngIf="ui.screenSizeIsGreater('sm')"
              class="heading-icons-section"
            >
              <img
                sqTooltip="View search in SDE"
                class="heading-icons"
                (click)="goToResultsPage(storyItems.searchText)"
                src="assets/img/search-story/searchStory_go_to_search.svg"
                alt=""
              />
              <img
                sqTooltip="Close"
                class="heading-icons"
                (click)="goToHomepage()"
                src="assets/img/search-story/searchStory_close_icon.svg"
                alt=""
              />
            </div>
            <div
              *ngIf="ui.screenSizeIsLessOrEqual('xs')"
              class="heading-mobile"
            >
              <button
                class="submit-button-mobile font-family-rajdhani-semibold"
                (click)="submitYours()"
              >
                <div class="submit-button-text-mobile">SUBMIT YOURS</div>
              </button>
              <div class="heading-icons-section-mobile">
                <img
                  class="heading-icons-mobile mr-12"
                  (click)="goToResultsPage(storyItems.searchText)"
                  src="assets/img/search-story/searchStory_go_to_search.svg"
                  alt=""
                />
                <img
                  class="heading-icons-mobile"
                  (click)="goToHomepage()"
                  src="assets/img/search-story/searchStory_close_icon.svg"
                  alt=""
                />
              </div>
            </div>

            <div class="heading-section">
              <div class="heading-1 font-family-rajdhani-medium">
                SCIENCE DISCOVERY ENGINE
              </div>
              <div class="font-family-rajdhani-bold heading-2">
                MY SEARCH STORY
              </div>
              <div
                class="searchStory-searchBar"
                [ngClass]="{
                  'searchBar-mobile': ui.screenSizeIsLessOrEqual('xs'),
                  'searchBar-web': ui.screenSizeIsGreater('sm')
                }"
              >
                <div class="searchBar-content">
                  <img
                    src="assets/img/search-story/searchStory_search_icon.svg"
                    alt="search icon"
                    class="search_icon"
                  />
                  <span
                    class="searchText font-family-inter-light"
                    [ngClass]="{
                      searchbarText: ui.screenSizeIsLessOrEqual('xs')
                    }"
                    >{{ storyItems.searchText }}</span
                  >
                </div>
              </div>
            </div>
            <div
              [ngClass]="{
                'description-section-mobile': ui.screenSizeIsLessOrEqual('xs'),
                'description-section': ui.screenSizeIsGreater('sm')
              }"
            >
              <div
                class="description-text"
                [ngClass]="{
                  'read-more-ellipsis':
                    ui.screenSizeIsGreaterOrEqual('xs') &&
                    !readMoreState[i] &&
                    !ui.screenSizeIsGreater('sm'),
                  'read-more-height':
                    ui.screenSizeIsGreaterOrEqual('xs') &&
                    readMoreState[i] &&
                    (searchStory.author === 'Eli Walker' ||
                      searchStory.author === 'BC Ray')
                }"
              >
                {{ storyItems.description }}
              </div>
              <div
                *ngIf="
                  ui.screenSizeIsGreaterOrEqual('xs') &&
                  (searchStory.author === 'Eli Walker' ||
                    searchStory.author === 'BC Ray') &&
                  !ui.screenSizeIsGreater('sm')
                "
                class="read-more-text"
                (click)="toggleReadMore(i)"
              >
                {{ readMoreState[i] ? "Read less" : "Read More" }}
              </div>

              <div class="author-section">
                <img
                  src="assets/img/search-story/description_line.svg"
                  alt="line icon"
                  class="line-icon"
                />
                <span class="author-text">{{ searchStory.author }}</span>
              </div>
            </div>

            <button
              (click)="submitYours()"
              *ngIf="ui.screenSizeIsGreater('sm')"
              class="submit-button font-family-rajdhani-semibold"
            >
              <div class="submit-button-text">SUBMIT YOURS</div>
            </button>
          </div>
        </slide>
      </ng-container>
    </ng-container>
  </carousel>
</div>
