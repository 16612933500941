<ng-container *ngIf="!isMobile">
  <ul
    role="presentation"
    *ngIf="!!searchtabs || !!customtabs"
    class="nav nav-tabs d-flex mb-1"
  >
    <ng-container *ngIf="!!searchtabs">
      <li
        role="tab"
        *ngFor="let tab of visibleTabs"
        class="nav-item text-center"
      >
        <a
          role="presentation"
          href="#"
          class="nav-link"
          [ngClass]="{
            active: tab.name == visibleTabSelected,
            disabled: isTabDisabled(tab)
          }"
          (click)="selectTab(tab)"
        >
          <i [ngClass]="iconMap[tab.name]"></i>
          {{ tab.display || tab.name | sqMessage }}
          <span class="count" *ngIf="showCounts"
            >({{ tab.count | sqNumber }})</span
          >
        </a>
      </li>
    </ng-container>

    <div class="btn-group" dropdown *ngIf="moreTabs.length > 0">
      <button
        id="button-animated"
        dropdownToggle
        type="button"
        class="btn btn-primary dropdown-toggle more-tabs-button font-family-rajdhani-semibold"
        aria-controls="dropdown-animated"
      >
        <img src="assets/img/more-tabs-icon.svg" class="me-2" alt="more-tabs" />
        More
      </button>
      <ul
        id="dropdown-animated"
        *dropdownMenu
        class="dropdown-menu more-tabs-menu"
        role="menu"
        aria-labelledby="button-animated"
      >
        <li role="menuitem" *ngFor="let tab of moreTabs">
          <a
            class="dropdown-item more-tabs-item ellipsis font-family-rajdhani-semibold"
            (click)="onMoreTabClick(tab, true)"
            [title]="getTabTooltip(tab)"
            [ngClass]="{ disabled: isTabDisabled(tab) }"
            >{{ tab.name }}
            <span class="count" *ngIf="showCounts"
              >({{ tab.count | sqNumber }})</span
            >
          </a>
        </li>
      </ul>
    </div>

    <ng-container *ngIf="!!customtabs">
      <li
        role="tab"
        *ngFor="let tab of visibleTabs"
        class="nav-item text-center"
        [ngClass]="{ active: tab == currentTab, disabled: isTabDisabled(tab) }"
      >
        <a
          role="presentation"
          href="#"
          class="nav-link"
          [ngClass]="{ active: tab == currentTab }"
          (click)="selectTab(tab, false)"
        >
          <i [ngClass]="iconMap[tab.name]"></i>
          {{ tab.display || tab.name | sqMessage }}
          <span class="count" *ngIf="showCounts && tab.count >= 0"
            >({{ tab.count | sqNumber }})</span
          >
        </a>
      </li>
    </ng-container>
  </ul>
</ng-container>

<div *ngIf="isMobile" class="tabs-container-mobile">
  <ng-container *ngIf="!!searchtabs">
    <div class="mobile-tabs">
      <div class="tabs d-flex">
        <div
          [ngClass]="{
            'tab-selected': tab == currentTab,
            disabled: isTabDisabled(tab)
          }"
          class="tab font-family-rajdhani-semibold"
          *ngFor="let tab of searchtabs.slice(1)"
        >
          <a
            href="#"
            [ngClass]="
              tab == currentTab ? 'link-text-selected' : 'link-text-unselected'
            "
            (click)="selectTab(tab)"
          >
            <span>{{ tab.display || tab.name | sqMessage }}</span>
            <span *ngIf="showCounts"> ({{ tab.count | sqNumber }}) </span>
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!!customtabs">
    <div
      role="tab"
      *ngFor="let tab of customtabs"
      class="nav-item text-center"
      [ngClass]="{ active: tab == currentTab, disabled: isTabDisabled(tab) }"
    >
      <a
        role="presentation"
        href="#"
        class="nav-link"
        [ngClass]="{ active: tab == currentTab }"
        (click)="selectTab(tab, false)"
      >
        <i [ngClass]="iconMap[tab.name]"></i>
        {{ tab.display || tab.name | sqMessage }}
        <span class="count" *ngIf="showCounts && tab.count >= 0"
          >({{ tab.count | sqNumber }})</span
        >
      </a>
    </div>
  </ng-container>
</div>
