import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SharedCommonService } from "../services/shared-common.service";

@Component({
  selector: "no-data-found",
  templateUrl: "./no-data-found.component.html",
  styleUrls: ["./no-data-found.component.scss"],
})
export class NoDataFoundComponent implements OnInit {
  @Input() customText: string = "";
  hideNoDataTemplate: boolean;

  constructor(
    private router: Router,
    private sharedCommonService: SharedCommonService
  ) {}

  ngOnInit(): void {}

  navigateToContactPage(): void {
    if (this.sharedCommonService.getAppDetailsForGA().app_name == "TDAMM") {
      this.router.navigate(["tdamm/contact-us"], {
        queryParams: {
          source: this.sharedCommonService.getAppDetailsForGA().app_name as any,
        },
      });
    } else if (
      this.sharedCommonService.getAppDetailsForGA().app_name == "SDE"
    ) {
      this.router.navigate(["/contact-us"], {
        queryParams: {
          source: this.sharedCommonService.getAppDetailsForGA().app_name as any,
        },
      });
    }
  }
}
