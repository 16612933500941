import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchpipe",
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], searchInput: string): any[] {
    if (!searchInput) {
      return items;
    }

    return items.filter((x) =>
      x.name.toLowerCase().includes(searchInput.toLowerCase())
    );
  }
}
